import hotOrganicButton from '~/components/Atoms/Organic/Button/Button.vue'
import modalSignup from '~/mixins/modalSignup.js'
import device from '~/mixins/device.js'

export default {
  mixins: [modalSignup, device],

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: false,
    },

    awards: {
      type: Array,
      required: true,
    },

    ctaDesktop: {
      type: Object,
      required: true,
    },

    ctaMobile: {
      type: Object,
      required: true,
    },
  },

  components: {
    hotOrganicButton,
    hotOrganicVideoInline: () => import('~/components/Molecules/Organic/VideoInline/VideoInline.vue'),
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },
}
