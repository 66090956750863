export default {
  props: {
    image: {
      type: Object,
    },

    srcMP4: {
      type: String,
      required: true,
    },

    srcWebm: {
      type: String,
      required: true,
    },

    autoplay: {
      type: Boolean,
      required: false,
    },

    loop: {
      type: Boolean,
      required: false,
    },

    muted: {
      type: Boolean,
      required: false,
    },

    controls: {
      type: Boolean,
      required: false,
    },

    width: {
      type: String,
    },

    height: {
      type: String,
    },

    alt: {
      type: String,
    },
  },

  mounted() {
    this.$refs.videoPlayer.defaultMuted = true
    var playPromise = this.$refs.videoPlayer.play()

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          video.pause()
        })
        .catch(error => {})
    }
  },

  computed: {
    transparentClasses() {
      if (this.transparent) return 'image--transparent'
    },

    aspectRatioClasses() {
      if (this.aspectRatio) return `aspect-ratio--${this.aspectRatio}`
    },
  },
}
