import { render, staticRenderFns } from "./Video.vue?vue&type=template&id=68554f09&scoped=true"
import script from "./Video.js?vue&type=script&lang=js&external"
export * from "./Video.js?vue&type=script&lang=js&external"
import style0 from "./Video.scss?vue&type=style&index=0&id=68554f09&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68554f09",
  null
  
)

export default component.exports